import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';
import { SharedService } from 'app/store/Shared/services/SharedServices';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
  getServices,
  getServicesSuccess,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  addPayment,
  addPaymentSuccess,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace-view-documents',
  templateUrl: './ui/marketplace-view-documents.component.html',
  styleUrls: ['./ui/marketplace-view-documents.component.scss'],
})
export class MarketplaceViewDocuments implements OnInit {
  subs: Subscription[] = [];
  id: string;
  company: any;
  userId: string | null = null;
  sellServiceId: string | null = null;
  paymentMethodId: string | null = null;
  buyerDocuments: any;
  continueButton = false;
  step: number = 1;

  documentForm = new FormGroup({});
  countries: any;
  regions: any;
  cities: any;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
    this._getUserInfo();
    this._getCompanyListener();
    this._getServices();
    this._getPaymentMethods();
    this._paymentSuccessListener();
    this._updateCompanyListener();

    this._setCountries();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getUserInfo() {
    let userInfo = localStorage.getItem('firmderWebUser');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).user.id;
    } else {
      this.router.navigate([`/account/marketplace/${this.id}`]);
    }
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data && data.buyersOfferAgreements) {
            if (data.buyersOfferAgreements.findIndex((e: any) => e === this.userId) !== -1) {
              this.company = JSON.parse(JSON.stringify(data));
              this.buyerDocuments = JSON.parse(JSON.stringify(this.company.buyerDocuments));
              this._setContinueButton();
              this._initForm();
            } else {
              this.router.navigate([`/account/marketplace/${this.id}`]);
            }
          } else {
            this.router.navigate([`/account/marketplace/`]);
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  private _getServices() {
    this.store.dispatch(getServices({data: {}}));

    this.subs.push(
      this.actions
        .pipe(ofType(getServicesSuccess.type))
        .subscribe(({data}: any) => {
          this.sellServiceId = data.filter((e: any) => e.title === 'Company Sale')[0].id;
        })
      )
  }

  private _getPaymentMethods() {
    this.store.dispatch(getPaymentMethods());

    this.subs.push(
      this.actions
        .pipe(ofType(getPaymentMethodsSuccess.type))
        .subscribe(({data}: any) => {
          this.paymentMethodId = data.find((e: any) => e.isDefault)!.paymentMethodId;
        })
      )
  }

  private _paymentSuccessListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(addPaymentSuccess.type))
        .subscribe(({data}: any) => {
          this.router.navigate([`/account/marketplace/${this.company.id}/documents`]);
        })
      )
  }

  private _initForm() {
    this.documentForm.addControl(
      'countryId',
      new FormControl(this.company.buyerDocuments.trackingData.countryId || null)
    );
    if (this.company.buyerDocuments.trackingData.countryId) {
      this._setRegions(this.company.buyerDocuments.trackingData.countryId);
    }

    this.documentForm.addControl(
      'regionId',
      new FormControl(this.company.buyerDocuments.trackingData.regionId || null)
    );
    if (this.company.buyerDocuments.trackingData.regionId) {
      this._setCities(this.company.buyerDocuments.trackingData.regionId);
    }

    this.documentForm.addControl(
      'cityId',
      new FormControl(this.company.buyerDocuments.trackingData.cityId || null)
    );

    this.documentForm.addControl(
      'street',
      new FormControl(this.company.buyerDocuments.trackingData.street || '')
    );

    this.documentForm.addControl(
      'house',
      new FormControl(this.company.buyerDocuments.trackingData.house || '')
    );

    this.documentForm.addControl(
      'building',
      new FormControl(this.company.buyerDocuments.trackingData.building || '')
    );

    this.documentForm.addControl(
      'room',
      new FormControl(this.company.buyerDocuments.trackingData.room || '')
    );

    this.documentForm.addControl(
      'zip',
      new FormControl(this.company.buyerDocuments.trackingData.zip || '')
    );

    this.documentForm.addControl(
      'postalService',
      new FormControl(this.company.buyerDocuments.trackingData.postalService || '')
    );

    this.documentForm.addControl(
      'trackingId',
      new FormControl(this.company.buyerDocuments.trackingData.trackingId || '')
    );

    this.documentForm.addControl(
      'comment',
      new FormControl(this.company.buyerDocuments.trackingData.comment || '')
    );
  }

  private _setCountries() {
    this.store
      .select((state) => state.shared)
      .subscribe((sharedState) => {
        if (sharedState.countries) {
          this.countries = sharedState.countries;
        }
      });
  }

  private _setRegions(countryId: string, isCountryChanged: boolean = false) {
    this.store
      .select((state) => state.shared)
      .subscribe((sharedState) => {
        if (sharedState.regions) {
          this.regions = sharedState.regions.filter((e: any) => e.inCountry === countryId);
          this.documentForm.patchValue({
            regionId: this.regions[0].id
          })
          if (isCountryChanged) {
            this.onRegionChange();
          }
        }
      });
  }

  private _setCities(regionId: string) {
    this.store
      .select((state) => state.shared)
      .subscribe((sharedState) => {
        if (sharedState.cities) {
          this.cities = sharedState.cities.filter((e: any) => e.inRegion === regionId);
        }
        this.documentForm.patchValue({
            cityId: this.cities[0].id
          })
      });
  }

  public onBuyClick() {
    const data = {
      companyId: this.company.id,
      paymentMethodId: this.paymentMethodId,
      serviceId: this.sellServiceId
    }
    this.store.dispatch(addPayment({data}));
  }

  public openLink(url: string) {
    window.open(url, "_blank");
  }

  public getStateName(state: number) {
    switch (state) {
      case 0:
        return "Empty"
      case 1:
        return "Pending"
      case 2:
        return "Approved"
      case 3:
        return "Declined"
      case 4:
        return "Sent"
      case 5:
        return "Received"
      case 6:
        return "Confirmed"
      default:
        return "Error"
    }
  }

  public handleFileInput(event: any, index: number) {
    const file:File = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "documents");
    
    this.sharedService.uploadFile(formData)
    .subscribe(({data}: any) => {
      this.buyerDocuments.documents[index].url = data.url;
      this.store.dispatch(updateTemplate({
        data: {
          buyerDocuments: this.buyerDocuments
        },
        id: this.id
      }))
    })
  }

  private _updateCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess.type))
        .subscribe(({ data }: any) => {
          this.buyerDocuments = JSON.parse(JSON.stringify(data.buyerDocuments));
        })
    );
  }

  private _setContinueButton() {
    this.continueButton = true;
    this.buyerDocuments.documents.map((e: any) => {
      this.continueButton = this.continueButton && e.state === 6
    });
  }

  public onCountryChange() {
    let countryId = this.documentForm.get('countryId')!.value;
    this._setRegions(countryId, true);
  }

  public onRegionChange() {
    let regionId = this.documentForm.get('regionId')!.value;
    this._setCities(regionId);
  }

  public onContinueButtonClick() {
    this.step = 2;
  }

  public onContinueDocumentButtonClick() {
    this.buyerDocuments.trackingData = this.documentForm.value;
    this.store.dispatch(updateTemplate({
      data: {
        buyerDocuments: this.buyerDocuments
      },
      id: this.id
    }))
  }

  public onToCompaniesButtonClick() {
    this.router.navigate([`/account/marketplace/`]);
  }
}
