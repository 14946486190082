<div *ngIf="company" class="marketplace-comission-container">
	<div class="title-container">
		<div class="company-title">
			{{ company.titleVariants[0].title }}:
		</div>
		<div class="comission-title">
			pay the commission
		</div>
	</div>

	<div class="progress-container">
		<div class="progress-text-row top-row">
			<div class="progress-text" style="top: 160px;">Pay the commission</div>
			<div class="progress-text" style="top: 160px; left: 585px;">Pay for the company</div>
			<div class="progress-text" style="top: 160px; left: 985px;">Re-registration of the company</div>
		</div>

		<div class="progress-line-container">
			<div class="progress-line"></div>
			<div class="progress-line-yellow"></div>
			<div class="ellipses-row">
				<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
				<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
			</div>
		</div>

		<div class="progress-text-row bottom-row">
			<div class="progress-text">Review the documents</div>
			<div class="progress-text">Documents</div>
			<div class="progress-text">Completed</div>
		</div>
	</div>

	<div class="payment-container">
		<div class="price-container">
			<div class="price-currency">{{ company.currency }}</div>
			<div class="price-number">{{ company.saleCommission }}</div>
		</div>
		<button class="payment-button" type="button" (click)="onBuyClick()">Pay</button>
	</div>
</div>
