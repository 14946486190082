<div *ngIf="company" class="marketplace-view-documents-container">
	<ng-container *ngIf="company.state === 5">
		<div class="title-container">
			<div class="company-title">
				{{ company.titleVariants[0].title }}:
			</div>
			<div class="view-documents-title">
				documents
			</div>
		</div>

		<div class="progress-container">
			<div class="progress-text-row top-row">
				<div class="progress-text" style="top: 160px;">Pay the commission</div>
				<div class="progress-text" style="top: 160px; left: 585px;">Pay for the company</div>
				<div class="progress-text" style="top: 160px; left: 985px;">Re-registration of the company</div>
			</div>

			<div class="progress-line-container">
				<div class="progress-line"></div>
				<div class="progress-line-yellow-5"></div>
				<div class="ellipses-row">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				</div>
			</div>

			<div class="progress-text-row bottom-row">
				<div class="progress-text">Review the documents</div>
				<div class="progress-text">Documents</div>
				<div class="progress-text">Completed</div>
			</div>
		</div>

		<ng-container *ngIf="step === 1">
			<table>
			  <tr class="table-header">
			    <th>Document</th>
			    <th>Sample</th>
			    <th>Status</th>
			    <th>Comment</th>
			  </tr>
			  <tr *ngFor="let document of buyerDocuments.documents; index as i" class="table-row">
			    <td>{{ document.name }}</td>
			    <td>
			    	<div class="download-sample-container">
				    	<img class="icon" src="assets/icons/documents.svg">
							<div class="document-title" (click)="openLink(document.sampleUrl)">
								Download sample
							</div> 
				    	<button *ngIf="document.state === 0 || document.state === 3" class="upload-button" (click)="fileUpload.click()">Upload</button>
				    	<input
				    		#fileUpload
			          type="file"
			          id="file"
			          (change)="handleFileInput($event, i)"
			          style="display: none;"
			        >
				    	<button *ngIf="document.state === 2 || document.state === 4 || document.state === 5 || document.state === 6" class="upload-button" (click)="openLink(document.url)">Download</button>
			    	</div>
			    </td>
			    <td>{{ getStateName(document.state) }}</td>
			    <td>{{ document.comment }}</td>
			  </tr>
			</table>

			<div class="description">
				After checking the documents, you will receive the text of the power of attorney and a list of required documents.
			</div>

			<div class="continue-button-container">
			  <button 
					class="continue-button" 
					[disabled]="!continueButton" 
					(click)="onContinueButtonClick()"
				> Continue </button>
			</div>
		</ng-container>


		<ng-container *ngIf="step === 2">
			<div class="docs-description-container">
				<div class="docs-description-title">Send original documents.</div>
				<div class="docs-description">
					Fill in the fields so that we can track the parcel. You can also attach a link with requirements and postal address here.
				</div>
			</div>

			<form class="document-form-container" [formGroup]="documentForm">
				<div class="document-form-column">
					<div class="input-container">
						<p class="label">Country</p>
						<select class="document-form-select" formControlName="countryId" (change)="onCountryChange()">
							<!-- <option value="null" disabled selected>Select country</option> -->
							<option *ngFor="let country of countries" [value]="country.id">{{ country.title }}</option>
						</select>
					</div>

					<div class="input-container">
						<p class="label">Region</p>
						<select 
							class="document-form-select" 
							formControlName="regionId" 
							(change)="onRegionChange()" 
						>
							<!-- <option value="null" disabled selected>Select region</option> -->
							<option *ngFor="let region of regions" [value]="region.id">{{ region.title }}</option>
						</select>
					</div>

					<div class="input-container">
						<p class="label">City</p>
						<select 
							class="document-form-select" 
							formControlName="cityId"
						>
							<!-- <option value="null" disabled selected>Select city</option> -->
							<option *ngFor="let city of cities" [value]="city.id">{{ city.title }}</option>
						</select>
					</div>


					<div class="input-container">
						<p class="label">Street</p>
						<input
							class="document-form-input"
							formControlName="street"
							placeholder="Street"
						>
					</div>
					
					<div class="input-container">
						<p class="label">House</p>
						<input
							class="document-form-input"
							formControlName="house"
							placeholder="House"
						>
					</div>
					
					<div class="input-container">
						<p class="label">Building</p>
						<input
							class="document-form-input"
							formControlName="building"
							placeholder="Building"
						>
					</div>
					
					<div class="input-container">
						<p class="label">Room</p>
						<input
							class="document-form-input"
							formControlName="room"
							placeholder="Room"
						>
					</div>
					
					<div class="input-container">
						<p class="label">Zip</p>
						<input
							class="document-form-input"
							formControlName="zip"
							placeholder="Zip"
						>
					</div>
				</div>

				<div class="document-form-column">
					<div class="input-container">
						<p class="label">Postal Service</p>
						<input
							class="document-form-input"
							formControlName="postalService"
							placeholder="Postal Service"
						>
					</div>

					<div class="input-container">
						<p class="label">Tracking Id</p>
						<input
							class="document-form-input"
							formControlName="trackingId"
							placeholder="Tracking Id"
						>
					</div>

					<div class="input-container">
						<p class="label">Comment</p>
						<input
							class="document-form-input"
							formControlName="comment"
							placeholder="Comment"
						>
					</div>
				</div>
			</form>

			<div class="continue-button-container">
			  <button 
					class="continue-button" 
					(click)="onContinueDocumentButtonClick()"
				> Continue </button>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="company.state === 6">
		<div class="title-container">
			<div class="company-title">
				{{ company.titleVariants[0].title }}:
			</div>
			<div class="view-documents-title">
				re-registration of documents
			</div>
		</div>

		<div class="progress-container">
			<div class="progress-text-row top-row">
				<div class="progress-text" style="top: 160px;">Pay the commission</div>
				<div class="progress-text" style="top: 160px; left: 585px;">Pay for the company</div>
				<div class="progress-text" style="top: 160px; left: 985px;">Re-registration of the company</div>
			</div>

			<div class="progress-line-container">
				<div class="progress-line"></div>
				<div class="progress-line-yellow-6"></div>
				<div class="ellipses-row">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/dark-ellipse.svg">
				</div>
			</div>

			<div class="progress-text-row bottom-row">
				<div class="progress-text">Review the documents</div>
				<div class="progress-text">Documents</div>
				<div class="progress-text">Completed</div>
			</div>
		</div>

		<table>
		  <tr class="table-header">
		    <th>Document</th>
		    <th>Status</th>
		    <th>Postal Service</th>
		    <th>Track ID</th>
		  </tr>
		  <tr *ngFor="let document of buyerDocuments.documents; index as i" class="table-row">
		    <td>{{ document.name }}</td>
		    <td>{{ getStateName(document.state) }}</td>
		    <td>{{ company?.buyerDocuments?.trackingData?.postalService || '-' }}</td>
		    <td>{{ company?.buyerDocuments?.trackingData?.trackingId || '-' }}</td>
		  </tr>
		</table>

		<div class="continue-button-container">
			  <button 
					class="continue-button" 
					[disabled]="!continueButton" 
					(click)="onToCompaniesButtonClick()"
				> To my companies </button>
			</div>
	</ng-container>

	<ng-container *ngIf="company.state === 7">
		<div class="title-container">
			<div class="company-title">
				{{ company.titleVariants[0].title }}:
			</div>
			<div class="view-documents-title">
				the deal is complete
			</div>
		</div>

		<div class="progress-container">
			<div class="progress-text-row top-row">
				<div class="progress-text" style="top: 160px;">Pay the commission</div>
				<div class="progress-text" style="top: 160px; left: 585px;">Pay for the company</div>
				<div class="progress-text" style="top: 160px; left: 985px;">Re-registration of the company</div>
			</div>

			<div class="progress-line-container">
				<div class="progress-line"></div>
				<div class="progress-line-yellow-7"></div>
				<div class="ellipses-row">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
					<img class="progress-ellipse" src="assets/icons/yellow-ellipse.svg">
				</div>
			</div>

			<div class="progress-text-row bottom-row">
				<div class="progress-text">Review the documents</div>
				<div class="progress-text">Documents</div>
				<div class="progress-text">Completed</div>
			</div>
		</div>

		<table>
		  <tr class="table-header">
		    <th>Document</th>
		    <th>Document preview</th>
		    <th>Postal Service</th>
		    <th>Track ID</th>
		  </tr>
		  <tr *ngFor="let document of buyerDocuments.documents; index as i" class="table-row">
		    <td>{{ document.name }}</td>
		    <td>
		    	<div class="download-sample-container">
			    	<img class="icon" src="assets/icons/documents.svg">
						<div class="document-title" (click)="openLink(document.sampleUrl)">
							{{ document.name }}
						</div>
		    	</div>
		    </td>
		    <td>{{ company?.buyerDocuments?.trackingData?.postalService || '-' }}</td>
		    <td>{{ company?.buyerDocuments?.trackingData?.trackingId || '-' }}</td>
		  </tr>
		</table>

		<div class="continue-button-container">
			  <button 
					class="continue-button" 
					[disabled]="!continueButton" 
					(click)="onToCompaniesButtonClick()"
				> To my companies </button>
			</div>
	</ng-container>
</div>
